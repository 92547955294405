@import './colors.scss';
@import './mixins.scss';
@import './variables.scss';

// Cursors
.cursor-pointer {
    cursor: pointer !important;
}

.cursor-grab {
    cursor: grab !important;
}

// Text Size
.big-text {
    font-size: $big-text;
}

.large-text {
    font-size: $large-text;
}

.huge-text {
    font-size: $huge-text;
}

// Misc
.dot {
    height: 25px;
    width: 25px;
    background-color: $black;
    border-radius: 50%;
}

.read {
    pointer-events: none;
}

.delay-indicator {
    width: 12px;
    height: 12px;
    border-radius: 50%;
}

.interactive-icon:hover {
    cursor: pointer;
    fill: $primary;
}

// FOR TESTING PURPOSE
.drag-test {
    height: 400px;
    width: 400px;
    background: $red;
    border: 2px solid $green;
}