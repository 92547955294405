/* Comments used by script */

// Colors
$black: #000000;
$black-light: #121212;
$blue: #0059b2;
$blue-light: #1a6aBa;
$blue-accent: #00bfff;
$blue-dark: #192b81;
$blue1: #f7f9fd;
$blue2: #ebf0fa;
$blue3: #C5D4F0;
$green: #008000;
$green-light: #4bb543;
$grey: #88929b;
$grey1: #e2e2e2;
$grey2: #f7f7f7;
$grey3: #666666;
$grey4: #687182;
$grey5: #ebebeb;
$red: #ff0000;
$red-dark: #c70000;
$white: #ffffff;
$orange: #ff9100;
$yellow: #ffff00;

$ontime-color: #FF9900;
$small-advance-color: #FF3300;
$large-advance-color: #990000;
$small-delay-color: #99CC00;
$large-delay-color: #008000;

$matrix-arrival: #696969;
$matrix-departure: $blue-accent;
$matrix-departure-small-delay: #FF00FF;
$matrix-departure-large-delay: #000080;
$matrix-large-delay: $green;
$matrix-small-delay: #99CC00;
$matrix-on-time: $orange;
$matrix-small-advance: #FF3300;
$matrix-large-advance: #990000;

$phony-distress: $red;
$phony-urgent: $orange;
$phony-simple: $yellow;
$phony-ongoing: $blue-accent;
// Colors End

// Color Variables
$primary: $blue;
$primary-light: $blue-light;
$primary-dark: $blue-dark;
$secondary: $white;
$disabled-color: $grey3;
$disabled-background: $grey2;
$disabled-border: $grey1;
$earlier: $red;
$late: $green;
$error: $red;
$success: $green-light;

$accent-background: $blue1;
$accent-text: $black;

$text-dark: $black;
$text-light: $grey4;

$hover-background: $blue2;
$hover-text: $black;

$select-background: $blue3;
$select-text: $black;

$border-color: $grey5;

$ontime: $ontime-color;
$small-advance: $small-advance-color;
$large-advance: $large-advance-color;
$small-delay: $small-delay-color;
$large-delay: $large-delay-color;

$stepper-active: $primary;
$stepper-completed: $primary-dark;
$stepper-disabled: $grey1;
// Color Variables End