@import '@ceccli/design-system/dist/src/styles/variables.scss';
@import '@ceccli/design-system/dist/src/styles/mixins.scss';

.cartography-page {
    .icon {
        width: 22px;
        height: 22px;
    }

    .label {
        font-size: $smallest-text;
        font-style: italic;
    }

    .button-container {
        font-size: $tiny-text;
    }

    .icon-selector {
        @include cardshadow();
        background: $secondary;
        z-index: 1;

        .icon-container {
            cursor: pointer;
            padding: 4px;

            &.selected {
                border: 1px solid $primary;
                border-radius: 6px;
            }
        }
    }
}