@import '@ceccli/design-system/dist/src/styles/colors.scss';

.days-container {
    display: flex;
    gap: 0.15rem;

    .day {
        width: 20px;
        text-align: center;
        border-radius: 6px;
        background: $disabled-background;
        color: $disabled-color;

        &.enabled {
            color: $secondary;
            background: $primary;
        }
    }
}

.mock-image {
    border-radius: 6px;
    padding: 12px;
    border: 1px solid $text-dark;
}