@import '@ceccli/design-system/dist/src/styles/colors.scss';

.intervention-settings-page {
    td:has(> .identifier-col) {
        width: 60px;
        margin: 0;
    }

    .label-size {
        color: $disabled-color;
        font-weight: lighter;
        white-space: nowrap;
    }

}
