@import '@ceccli/design-system/dist/src/styles/colors.scss';
@import '@ceccli/design-system/dist/src/styles/mixins.scss';

.phony-indicator {
    .chip-container {
        transform: translate(-50%, 0%);
        margin-right: -6px;
    }
    .phony-chip {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        transform: translateY(80%);
        color: $black;
        background: $secondary;
        z-index: 1;

    }

    @include media-breakpoint-down(md) {
        margin-bottom: 0 !important;

        .phony-chip {
            top: -4px;
        }
    }
}