@import '@ceccli/design-system/dist/src/styles/colors.scss';
@import '@ceccli/design-system/dist/src/styles/mixins.scss';

.overlay-card {
    @include cardshadow();
    background: $secondary;
    font-size: 12px;

    .icon {
        width: 22;
        height: 22;
    }

    .title {
        font-weight: 500;
        text-transform: capitalize;

    }

    .content {
        &::before {
            display: block;
            content: '';
            width: 100%;
            height: 1px;
            background: $text-light;
            margin: 6px 0px;
        }

        .content-grid {
            display: grid;
            grid-template-columns: auto auto;
            column-gap: 16px;

            .separator {
                height: 12px;
            }

            .label {
                color: $text-light;
            }
        }

    }
}