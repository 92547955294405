@import '~bootstrap/scss/bootstrap-reboot';
@import './colors.scss';
@import './variables.scss';

@mixin cardshadow() {
    border-radius: 8px !important;
    box-shadow: 0px 0px 0px 1px rgba(152, 161, 178, 0.1), 0px 1px 4px rgba(69, 75, 87, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.08);
}

@mixin cardshadowerror () {
    box-shadow: 0px 0px 0px 1px rgba(255, 0, 0, 0.5);
}

@mixin cardfocus() {
    box-shadow: 0 0 0 1px $select-background !important;
}

@mixin cardheader() {
    background: $accent-background;
    color: $accent-text;
    font-weight: 600 !important;
    font-size: 18px;
    line-height: 100%;
    padding: 12px 0px;
    letter-spacing: 0.04em;
}

@mixin accent() {
    background: $accent-background;
    color: $accent-text;
}

@mixin scrollbar($size) {
    ::-webkit-scrollbar {
        width: $size;
        height: $size;
    }

    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 $size rgba(0,0,0,0.3);
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 $size rgba(0,0,0,0.5);
    }
}