@import '@ceccli/design-system/dist/src/styles/variables.scss';
@import '@ceccli/design-system/dist/src/styles/colors.scss';

.login-page {
    background: url('../../../assets/background_bus.jpg');
    background-position: center;
    background-size: cover;

    .title {
        color: $primary;
        text-transform: uppercase;
        font-size: $large-text;
        white-space: nowrap;
    }

    .login-form {
        min-width: 30vw;
        padding: 0px 64px;
        padding-bottom: 16px;
    }
}