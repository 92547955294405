@import '@ceccli/design-system/dist/src/styles/colors.scss';

.menu-bundle {
    .header {
        border-bottom: 1px solid $primary;

        .title {
            color: $primary;
        }
    }
}