@import '@ceccli/design-system/dist/src/styles/colors.scss';

.line-attachment-page {
    tbody {
        tr:nth-child(4n + 3), tr:nth-child(4n + 4) {
            td {
                background: $accent-background;
            }
        }
    }
}