@import '@ceccli/design-system/dist/src/styles/colors.scss';
@import '@ceccli/design-system/dist/src/styles/mixins.scss';

.general-layout {
    .general-content {
        height: 100%;

        @include media-breakpoint-down(md) {
            height: calc(100% - 50px);
        }

        .phony-draggable {
            z-index: 10;
            top: 12px;
            left: 12px;

            .card-container {
                background: $secondary;

                .header {
                    padding: 12px;
                }
            }
        }
    }
}