@import '~bootstrap/scss/bootstrap-reboot';
@import '@ceccli/design-system/dist/src/styles/colors.scss';

.passenger-information-modal-container {
    min-width: 640px;
    min-height: 450px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .select-broadcast {
        max-width: 640px;
        display: flex;
        align-items: center;
        gap: 16px;
    }

    .icon {
        margin-top: 15px;
    }

    .days {
        .checkbox-container {
            margin-top: 30px;
        }
    }

    .content {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 16px;
    }

    @include media-breakpoint-down(md) {
        min-width: 361px;
        min-height: 620px;

        .content {
            grid-template-columns: repeat(1, 1fr);
        }
    }

    @include media-breakpoint-down(sm) {
        min-width: 361px;
        min-height: 620px;

        .content {
            grid-template-columns: repeat(1, 1fr);
        }
    }
}