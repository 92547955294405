@import '@ceccli/design-system/dist/src/styles/colors.scss';

.phony-page {
    .phony-collapsible {
        .collapsible-collapse {
            margin: 2px;
        }

        &:not(.disabled) {
            .phony-collapsible-header:hover {
                color: $primary;

                .icon {
                    background: $primary !important;
                }
            }
        }
    }
}
