@import '@ceccli/design-system/dist/src/styles/colors.scss';
@import '@ceccli/design-system/dist/src/styles/mixins.scss';

.hazard-page {
    .hazard-collapsible {
        .collapsible-collapse {
            margin: 2px;
        }

        .hazard-collapsible-header:hover {
            color: $primary;

            .icon {
                background: $primary !important;
            }
        }
    }
}

.hazard-modal {
    .error {
        .time-picker {
            input {
                @include cardshadowerror();
            }
        }
    }
}
