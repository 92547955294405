@import '@ceccli/design-system/dist/src/styles/colors.scss';

.park-tracking-page {
    &.matrix {
        table {
            thead {
                tr {
                    th:not(:first-child) {
                        div {
                            justify-content: center;
                        }
                    }
                }
            }

            tbody {
                tr {
                    td {
                        &:not(:first-child) {
                            text-align: center;
                        }

                        &:has(.matrix-cell) {
                            border: 1px solid $white;
                            border-top: 0;
                            border-bottom: 0;

                            &:has(.colorized) {
                                color: $white !important;
                            }
                        }

                        &:has(.arrival) {
                            background: $matrix-arrival;
                        };

                        &:has(.departure) {
                            background: $matrix-departure;
                        }

                        &:has(.departure-small-delay) {
                            background: $matrix-departure-small-delay;
                        }

                        &:has(.departure-large-delay) {
                            background: $matrix-departure-large-delay;
                        }

                        &:has(.large-delay) {
                            background: $matrix-large-delay;
                        }

                        &:has(.small-delay) {
                            background: $matrix-small-delay;
                        }

                        &:has(.on-time) {
                            background: $matrix-on-time;
                        }

                        &:has(.small-advance) {
                            background: $matrix-small-advance;
                        }

                        &:has(.large-advance) {
                            background: $matrix-large-advance;
                        }
                    }
                }
            }
        }

        &:not(.show-parkId) {
            tbody {
                tr {
                    td {
                        &:not(:first-child) {
                            width: 70px;
                        }
                    }
                }
            }
        }
    }
}