@import '@ceccli/design-system/dist/src/styles/colors.scss';
@import '@ceccli/design-system/dist/src/styles/variables.scss';
@import '@ceccli/design-system/dist/src/styles/mixins.scss';


.homepage {
    .homepage-container {
        row-gap: 100px;
    }

    .bundle {
        flex-basis: 30%;

        @include media-breakpoint-down(xl) {
            flex-basis: 40%;
        }

        @include media-breakpoint-down(md) {
            flex-basis: 100%;
        }
    }
}

.app-title {
    color: $primary;
    text-transform: uppercase;
    font-size: $large-text;
    white-space: nowrap;
}
