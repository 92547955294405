@import '~bootstrap/scss/bootstrap-utilities';

.settings-header {
    display: grid;
    grid-template-columns: 64px auto auto;

    @include media-breakpoint-down(sm) {
        column-gap: 32px;
    }
}
